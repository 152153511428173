
import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import IconButton from '@material-ui/core/IconButton';
import Button from "components/CustomButtons/Button.js";
import Media from "components/Media/Media.js";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MediaElement from "components/Media/MediaElement.js";

import { Link } from "react-router-dom";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import { BASE_URL, PHOTO_BASE_URL } from 'variables/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  cardItem: {
    marginBottom: 10,
  },
  success: {
    color: "#43a047"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

}));



export default function UserList() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const classes = useStyles();


  const [tab, setTab] = useState(0);
  const [title, setTitle] = useState("All Users");

  const [open, setOpen] = useState(false);
  const [approveIcon, setApproveIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(true);


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };



  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);

    if (newValue == 0) {
      setApproveIcon(false);
      fetchAllUsers();
    } else {
      setApproveIcon(true);
      fetchPendingProfileVerificationUsers();
    }
  };


  useEffect(() => {
    fetchAllUsers();
  }, []);




  const fetchAllUsers = () => {
    setTitle("All Users");


    fetch(`${BASE_URL}admin/accounts`)
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }

        response.json().then((data) => {
          console.log(data);
          setUsers(data.results)
        });
      }
      )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };


  const fetchPendingProfileVerificationUsers = () => {
    setTitle("Profile Verifications");

    fetch(`${BASE_URL}admin/pending-profile-verification`)
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }

        response.json().then((data) => {
          console.log(data);
          setUsers(data.results)
        });
      }).catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };


  const approveProfile = () => {
    setOpen(false);
    setLoading(true);
    fetch(`${BASE_URL}admin/approve-profile`, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: `account_id=${selectedUser.id}`
    })
      .then((response) => {
        setLoading(false);
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        response.json().then((data) => {
          console.log(data);

          if (data.success) {
            var index = users.indexOf(selectedUser);
            console.log("Index of found item " + index);
            setUsers(users.slice(0, index));
          }
          setMessage(data.message);
          clearMessage();
        });
      }
      )
      .catch(function (err) {
        setLoading(false);
        console.log('Fetch Error :-S', err);
      });
  };


  const rejectProfile = () => {
    setLoading(true);
    setOpen(false);
    fetch(`${BASE_URL}admin/reject-profile`, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: `account_id=${selectedUser.id}&reason=${reason}`
    })
      .then((response) => {
        setLoading(false);
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        response.json().then((data) => {
          console.log(data);

          setMessage(data.message);
          clearMessage();
        });
      }
      )
      .catch(function (err) {
        setLoading(false);
        console.log('Fetch Error :-S', err);
      });
  };

  function clearMessage() {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  }

  return (
    <>
      <Tabs value={tab} onChange={handleChange}>
        <Tab label="All Users" />
        <Tab label="Profile Verifications" />
      </Tabs>

      <br />
      <GridContainer>

        {message ? <p className={classes.success}>{message}</p> : null}
        {users.map((user, key) => {
          return (
            <GridItem xs={4} sm={4} md={4} key={key} >
              <Card className={classes.cardItem}>


                <MediaElement
                  media={user.profile_photo}
                />

                <CardContent>
                  <Typography variant="h6" component="p">
                    {user.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {user.bio}
                  </Typography>
                </CardContent>
                <CardActions>

                  <Link to={`/manage/verify-profile-photos/${user.id}`} target="_blank">
                    <Button variant="contained" color="primary">
                      Approve Profile Photos
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </GridItem>


          );
        })}

        <Dialog open={open} onClose={handleClose}>
          {loading ? <CircularProgress /> : <div>
            <DialogTitle id="form-dialog-title">Approve Profile
          <IconButton
                className={classes.closeButton}
                aria-label="Close"
                onClick={handleClose}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Do you want to approve {selectedUser.name}'s  profile?

          </DialogContentText>
              <TextareaAutosize className={classes.textarea}
                onChange={(event) => {
                  setReason(event.target.value);
                }}
                value={reason}

                rowsMin={5} width="100%" placeholder="Reason(s) for accepting/rejecting profile" />
            </DialogContent>
            <DialogActions>
              <Button onClick={rejectProfile} color="primary">
                No
          </Button>
              <Button onClick={approveProfile} color="primary">
                Yes
          </Button>
            </DialogActions></div>}

        </Dialog>
      </GridContainer>
    </>
  );
}
