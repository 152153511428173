
import React, { useState, useEffect } from 'react';


import { makeStyles } from '@material-ui/core/styles';
// core components
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from "components/CustomButtons/Button.js";
import Media from "components/Media/Media.js";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Close from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";


import { BASE_URL, PHOTO_BASE_URL } from 'variables/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    cardItem: {
        marginBottom: 10,
    },
    success: {
        color: "#43a047"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

}));

export default function Adverts() {
    const [adverts, setAdverts] = useState([]);
    const [advertStatus, setAdvertStatus] = useState("in_review");
    const [selectedAdvert, setSelectedAdvert] = useState(null);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);
    const classes = useStyles();


    useEffect(() => {
        fetchAdverts();
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const fetchAdverts = () => {
        setAdverts([]);
        fetch(`${BASE_URL}admin/adverts?advert_status=${advertStatus}`)
            .then((response) => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }

                // Examine the text in the response
                response.json().then((data) => {
                    // console.log(data);
                    setAdverts(data);
                });
            }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });

    }

    const handleChange = (event, newValue) => {
        setTab(newValue);

        setAdverts([]);

        switch (newValue) {
            case 0:
                setAdvertStatus("in_review");
                break;
            case 1:
                setAdvertStatus("approved");
                break;
            case 2:
                setAdvertStatus("active");
                break;
        }

        fetchAdverts();
    };

    const approveAdvert = () => {
        setOpen(false);
        setLoading(true);
        fetch(`${BASE_URL}admin/approve-advert/${selectedAdvert.id}`, {
            method: 'put'
        })
            .then((response) => {
                setLoading(false);
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then((data) => {
                    // console.log(data);

                    if (data.success) {
                        setTab(1);
                        setAdvertStatus("approved");
                        fetchAdverts();
                    }
                    setMessage(data.message);
                    clearMessage();
                });
            }
            )
            .catch(function (err) {
                setLoading(false);
                console.log('Fetch Error :-S', err);
            });
    };

    function clearMessage() {
        setTimeout(() => {
            setMessage("");
        }, 3000);
    }


    return (
        <>
            <Tabs value={tab} onChange={handleChange}>
                <Tab label="In Review" />
                <Tab label="Approved" />
                <Tab label="Active" />
            </Tabs>
            <br />
            <GridContainer>
                {adverts.map((advert, key) => {
                    return (
                        <GridItem xs={4} sm={4} md={4} key={key} >
                            <Card className={classes.cardItem}>
                                <CardHeader
                                    avatar={
                                        <Avatar alt={advert.uploader.name} src={`${PHOTO_BASE_URL}${advert.uploader.profile_photo}`} />
                                    }
                                    action={
                                        <IconButton aria-label="settings">
                                            <MoreVertIcon />
                                        </IconButton>
                                    }
                                    title={advert.uploader.name}
                                    subheader={advert.time_ago}
                                />

                              
                                <Media
                                    mediaFiles={advert.media_files}
                                />
                           
                                <CardContent>
                                    <Typography variant="h6" component="p">
                                        {advert.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {advert.description}
                                    </Typography>
                                </CardContent>

                                {tab == 0 ?
                                    <CardActions disableSpacing>
                                        <Button color="success" onClick={(ev) => {
                                            setOpen(true);
                                            setSelectedAdvert(advert);
                                        }}>Approve Advert</Button>
                                    </CardActions>
                                    : null
                                }

                            </Card>
                        </GridItem>
                    );
                })}
            </GridContainer>
            <Dialog open={open} onClose={handleClose}>

                {loading ? <CircularProgress /> : <div>
                    <DialogTitle>Approve Advert
                        <IconButton
                            className={classes.closeButton}
                            aria-label="Close"
                            onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Do you want to approve {selectedAdvert && selectedAdvert.title}'s advert?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="danger" onClick={handleClose}>
                            No
                        </Button>
                        <Button onClick={approveAdvert} color="success">
                            Yes
                    </Button>
                    </DialogActions></div>}
            </Dialog>
        </>
    );

}