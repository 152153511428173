
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import { BASE_URL, PHOTO_BASE_URL } from 'variables/constants';

const useStyles = makeStyles((theme) => ({
    root: {
      
    },
    media: {
      width: "32vw",
      height: "40vh"
    },
  }));

export default function ProfilePhotos() {
    const  { userId } = useParams();
    const [profilePhotos, setProfilePhotos] = useState([]);

    const classes = useStyles();


    useEffect(() => {
        fetch(`${BASE_URL}admin/profile-photos/${userId}`)
            .then((response) => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }

                // Examine the text in the response
                response.json().then((data) => {
                    console.log(data);
                    setProfilePhotos(data);
                });
            }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });

    }, []);


    return (
        <GridContainer>
            {profilePhotos.map((profilePhoto,key) => {
                return (
                    <GridItem  xs={4} sm={4} md={4} key={key}> 
                            <img
                                className={classes.media}
                                src={`${PHOTO_BASE_URL}${profilePhoto.photo}`}
                            />
                    </GridItem>
                );
            })}
        </GridContainer>
    );

}