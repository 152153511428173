
export const BASE_URL = "https://api.myflamingoes.com/v1/";
export const PHOTO_BASE_URL = "https://api.myflamingoes.com";
export const MEDIA_BASE_URL = "https://api.myflamingoes.com";


// export const BASE_URL ="http://localhost:8000/v1/";
// export const PHOTO_BASE_URL =  "http://localhost:8000";
// export const MEDIA_BASE_URL =  "http://localhost:8000";

