import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";


// @material-ui/icons
import Email from "@material-ui/icons/Email";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import loginPageStyle from "assets/jss/material-dashboard-react/views/loginPageStyle.js";

import { BASE_URL } from 'variables/constants';

const useStyles = makeStyles(loginPageStyle);
export default function LoginPage() {
  const classes = useStyles();
  const [errors] = useState("");
  const [message, setMessage] = useState("");
  let history = useHistory();

  function login(e) {
    e.preventDefault();
    const formElements = e.target.elements;

    let username = formElements.namedItem("username").value;
    let password = formElements.namedItem("password").value;

    fetch(`${BASE_URL}admin/login`, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: `username=${username}&password=${password}`
    })
      .then((response) => {
        // setLoading(false);
        
        response.json().then((data) => {
          console.log(JSON.stringify(data));

          if (data.success) {
            //  history.push("/dashboard");
            setMessage(data.message);
             window.location = "/manage/dashboard";
          } else {
            setMessage(data.message);
          }
        });
      }
      )
      .catch(function (err) {
        //setLoading(false);
        console.log('Fetch Error :-S', err);
      });

  };
  

  return (
    <div className={classes.container}>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={login}>
            <Card>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>My Flamingoes Admin</h4>
              </CardHeader>
              <CardBody>
                <p
                  className={`${classes.textCenter} ${classes.checkboxLabel}`}
                >
                  <strong>Sign In</strong>
                </p>
                <p className={classes.errorMessage}>{true && message}</p>
                <CustomInput
                  labelText="Username"
                  id="email"
                  error={errors.username || errors.invalidEmailOrPassword}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName
                  }}
                  inputProps={{
                    required: true,
                    name: "username",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  error={errors.password || errors.invalidEmailOrPassword}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName
                  }}
                  inputProps={{
                    type: "password",
                    required: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                          </Icon>
                      </InputAdornment>
                    )
                  }}
                />
               
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="primary" simple size="lg" block>
                  Login
                  </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}




