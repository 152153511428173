
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Checkbox from '@material-ui/core/Checkbox';
import MediaElement from "components/Media/MediaElement.js";
import Button from '@material-ui/core/Button';

import { BASE_URL } from 'variables/constants';


const useStyles = makeStyles((theme) => ({
    gridItem: {
        position: "relative",
        width: "25vw",
        height: "25vh",

        "& $roundCheckbox": {
            position: "absolute",
            top: "15%",
            left: "15%",
            border:"1px solid red"
    
        },
        "& $media": {
            width: "100%",
            height: "100%",
            border:"1px solid red"
        }, 
    },
    roundCheckbox: {
        position: "absolute",
        top: "15%",
        left: "15%",
        border:"1px solid red"



    },
    media: {
        width: "100%",
        height: "100%",
        border:"1px solid red"
        
    }, 
    success: {
        color: "#43a047"
    }

}));

export default function VerifyProfilePhotos() {
    const { userId } = useParams();
    const [profilePhotos, setProfilePhotos] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    useEffect(() => {
        fetch(`${BASE_URL}admin/profile-photos/${userId}`)
            .then((response) => {
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }

                // Examine the text in the response
                response.json().then((data) => {
                    console.log(data);
                    setProfilePhotos(data);
                });
            }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });

    }, []);

    function clearMessage() {
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }

    const approvePhotos = () => {
        let photoIds = selectedPhotos.join();
        console.log(`Photo Id: ${photoIds}`);
        console.log(`User Id:${userId}`);
        fetch(`${BASE_URL}/admin/approve-profile-photos`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: `account_id=${userId}&profile_photos_ids=${photoIds}`
        })
            .then((response) => {
                setLoading(false);
                if (response.status !== 200) {
                    console.log('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }
                response.json().then((data) => {
                    console.log(data);

                    if (data.success) {
                    }
                    setMessage(data.message);
                    clearMessage();
                });
            }
            )
            .catch(function (err) {
                setLoading(false);
                console.log('Fetch Error :-S', err);
            });
    };

    const handleChange = (event, profilePhoto) => {
        if (event.target.checked) {
            selectedPhotos.push(profilePhoto.id);
        } else {
            selectedPhotos.pop(profilePhoto.id);
        }

    };


    return (

        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <SnackbarContent message={"Select profile photo(s) to verify"} />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <br />
                {message ? <p className="success">{message}</p> : null}
            </GridItem>

            {profilePhotos.map((profilePhoto, key) => {
                return (
                    <GridItem className={classes.gridItem} xs={4} sm={4} md={4} key={profilePhoto.id}>
                        <MediaElement
                            media={profilePhoto.photo}
                        />
                        <div className={classes.roundCheckbox}>
                            <input type="checkbox" id="checkbox" onChange={(ev)=>{handleChange(ev, profilePhoto)}} />
                            <label for="checkbox"></label>
                        </div>


                    </GridItem>
                );
            })}
            <GridItem xs={12} sm={12} md={12}>
                <Button variant="contained" color="primary" onClick={approvePhotos}>
                    Approve Profile Photo
                </Button>
            </GridItem>
        </GridContainer>
    );

}