import React from "react";
import { MEDIA_BASE_URL } from 'variables/constants';
import { makeStyles } from "@material-ui/core/styles";

const styles = {
    media: {
        width: "100%",
        height: "300px",
        margin: "0 auto"
    }
};

const useStyles = makeStyles(styles);

export default function MediaElement(props) {
    const classes = useStyles();
    let { media } = props;
    let mediaUrl="https://i.stack.imgur.com/l60Hf.png";
    let ext;
    if(media){
        ext = media.substr(media.lastIndexOf(".") + 1);
        mediaUrl = MEDIA_BASE_URL + "" + media;
    
        if(media.startsWith("http")||media.startsWith("https")){
            mediaUrl = media;
        }
    }
    
    if (ext == "mp4") {
        return (<video className={classes.media} controls>
            <source src={mediaUrl} type={`video/${ext}`}></source>
        </video>);
    }
    return <img className={classes.media} src={mediaUrl}  />;
}