/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LiveTvIcon from '@material-ui/icons/LiveTv';

import ReportIcon from '@material-ui/icons/Report';
// core components/views for Admin layout
import Adverts from "views/Adverts/Adverts.js";
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserList from "views/UserList/UserList.js";
import ReportedProfiles from "views/UserList/ReportedProfiles.js";
import Discussions from "views/Discussions/Discussions.js";
import Events from "views/Events/Events.js";
import Groups from "views/Groups/Groups.js";
import ProfilePhotos from "views/ProfilePhotos/ProfilePhotos.js";
import VerifyProfilePhotos from "views/ProfilePhotos/VerifyProfilePhotos.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import LoginPage from "views/Login/LoginPage.js";


const dashboardRoutes = [
  {
    path: "/manage/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/manage/users",
    name: "Users",
    icon: Person,
    component: UserList,
    layout: "/admin"
  },
  ,
  {
    path: "/manage/adverts",
    name: "Adverts",
    icon: LiveTvIcon,
    component: Adverts,
    layout: "/admin"
  },
  {
    path: "/manage/discussions",
    name: "Discussions",
    icon: "content_paste",
    component: Discussions,
    layout: "/admin"
  },
  {
    path: "/manage/events",
    name: "Events",
    icon: LibraryBooks,
    component: Events,
    layout: "/admin"
  },
  {
    path: "/manage/groups",
    name: "Groups",
    icon: BubbleChart,
    component: Groups,
    layout: "/admin"
  },
  {
    path: "/manage/reported-profiles",
    name: "Reported Profiles",
    icon: ReportIcon,
    component: ReportedProfiles,
    layout: "/admin"
  },
  {
    path: "/manage/profile-photos/:userId",
    name: "Profile Photos",
    icon: ReportIcon,
    component: ProfilePhotos,
    layout: "/account"
  },
  {
    path: "/manage/verify-profile-photos/:userId",
    name: "Profile Photos",
    icon: ReportIcon,
    component: VerifyProfilePhotos,
    layout: "/account"
  },
  {
    path: "/manage/my-profile",
    name: "My Profile",
    icon: BubbleChart,
    component: UserProfile,
    layout: "/account"
  }
  
];

export default dashboardRoutes;
