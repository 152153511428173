import { dangerColor } from "assets/jss/material-dashboard-react.js";
const mediaStyle = {
    /* Slideshow container */
    slideshowContainer: {
        maxWidth: "1000px",
        position: "relative",
        margin: "auto",

    },

    mySlides: {
        display: "none",
    },

    slideIndicator: {
        textAlign: "center"
    },

    /* The dots/bullets/indicators */
    dot: {
        cursor: "pointer",
        height: "10px",
        width: "10px",
        margin: "0 2px",
        backgroundColor: "#bbb",
        borderRadius: "50%",
        display: "inline-block",
        transition: "background-color 0.6s ease",
        "&:hover": {
            backgroundColor: dangerColor[0]
        }
    },

    show: {
        display: "block"
    },
    hide: {
        display: "none"
    },
    active: {
        backgroundColor: dangerColor[0]
    },
    '@keyframes fade': {
        from: {
            opacity: .4
        },

        to: {
            opacity: 1
        }
    },
    fade: {
        animationName: "$fade",
        animationDuration: "1.5s"
    }
};

export default mediaStyle;