
import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Avatar from '@material-ui/core/Avatar';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MediaElement from "components/Media/MediaElement.js";


import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from "@material-ui/core/IconButton";

import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import BlockIcon from '@material-ui/icons/Block';
import Close from "@material-ui/icons/Close";


import { BASE_URL, PHOTO_BASE_URL } from 'variables/constants';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  cardItem: {
    marginBottom: 10,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  button: {
    margin: theme.spacing(1),
  }

}));

export default function ReportedProfiles() {
  const [reportedProfiles, setReportedProfiles] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const classes = useStyles();


  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchReportedProfiles();
  }, []);




  const fetchReportedProfiles = () => {

    fetch(`${BASE_URL}admin/reported-profiles`)
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }

        response.json().then((data) => {
          console.log(data);
          setReportedProfiles(data.results)
        });
      }
      )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };



  const blockProfile = () => {
    setLoading(true);
    setOpen(false);
    fetch(`${BASE_URL}admin/reject-profile`, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: `account_id=${selectedUser.id}&reason=${reason}`
    })
      .then((response) => {
        setLoading(false);
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }
        response.json().then((data) => {
          console.log(data);

          setMessage(data.message);
          clearMessage();
        });
      }
      )
      .catch(function (err) {
        setLoading(false);
        console.log('Fetch Error :-S', err);
      });
  };

  function clearMessage() {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  }

  return (
    <GridContainer>

      {reportedProfiles.map((reportedProfile, key) => {
        return (

          <GridItem xs={4} sm={4} md={4} key={key}>
            <Card className={classes.cardItem}>
              <CardHeader
                avatar={
                  <Avatar alt={reportedProfile.accusser.name} src={`${PHOTO_BASE_URL}${reportedProfile.accusser.profile_photo}`} />
                }

                title={`${reportedProfile.accusser.name} (Accusser)`}
              />
              <MediaElement
                media={reportedProfile.accussed.profile_photo}
              />
              <CardContent>
                <Typography variant="h6" component="p">
                  {`${reportedProfile.accussed.name} (Accussed)`}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {reportedProfile.reason}
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" className={classes.button} color="secondary" startIcon={<BlockIcon />} onClick={() => {
                  setOpen(true);
                }}>
                  Block User
                </Button>

              </CardActions>
            </Card>
          </GridItem>
        );
      })}

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">

        {loading ? <CircularProgress /> : <div>
          <DialogTitle id="form-dialog-title">Block Profile
          <IconButton
              className={classes.closeButton}
              aria-label="Close"
              onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to block {selectedUser.name}'s  profile?

          </DialogContentText>
            <TextareaAutosize className={classes.textarea}
              onChange={(event) => {
                setReason(event.target.value);
              }}
              value={reason}

              rowsMin={5} width="100%" placeholder="Reason(s) for accepting/rejecting profile" />
          </DialogContent>
          <DialogActions>
            <Button color="primary">
              No
          </Button>
            <Button color="primary">
              Yes
          </Button>
          </DialogActions></div>}

      </Dialog>
    </GridContainer>
  );
}
