import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components;
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import MediaElement from "./MediaElement.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/mediaStyle.js";




const useStyles = makeStyles(styles);
//https://www.w3schools.com/howto/howto_js_slideshow.asp
export default function Media(props) {
  const classes = useStyles();
  const { mediaFiles } = props;
 
  let [currentSlide, setCurrentSlide] = useState(0);
  

  if (mediaFiles) {
    return (
      <div className={classes.slideshowContainer}>
        {mediaFiles.map((mediaFile, key) => {
          return (
            <div className={`${classes.mySlides} ${classes.fade} ${currentSlide==key?classes.show:classes.hide}`} key={mediaFile.id} >
              <MediaElement media={mediaFile.media} />
            </div>
          );
        })}


        <div className={`${classes.slideIndicator} ${mediaFiles.length>1?classes.show:classes.hide}`}>
          {
          mediaFiles.map((mediaFile, key) => {
            
            return (
              <span className={`${classes.dot} ${currentSlide==key?classes.active:""}`} key={mediaFile.id} onClick={() => setCurrentSlide(key)}></span>
            );
          })}

        </div>

      </div>
    );
  }
  return (
    <div>
    </div>
  );
}


