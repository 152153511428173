
import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import MediaElement from "components/Media/MediaElement.js";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { BASE_URL, PHOTO_BASE_URL } from 'variables/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  cardItem: {
    marginBottom: 10,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  }

}));

export default function Events() {
  const [events, setEvents] = useState([]);
  const classes = useStyles();


  useEffect(() => {
    fetch(`${BASE_URL}admin/events`)
      .then((response) => {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' +
            response.status);
          return;
        }

        // Examine the text in the response
        response.json().then((data) => {
          console.log(data);
          setEvents(data.results)
        });
      }
      )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });

  }, []);


  return (
    <GridContainer>
      {events.map((event, key) => {
        return (
          <GridItem xs={4} sm={4} md={4} key={key}>
            <Card className={classes.cardItem}>
              <CardHeader
                avatar={
                  <Avatar alt={event.uploader.name} src={`${PHOTO_BASE_URL}${event.uploader.profile_photo}`} />
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                title={event.uploader.name}
              />
              <MediaElement
                media={event.poster}
              />

              <CardContent>
                <Typography variant="h6" component="p">
                  {event.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {event.description}
                </Typography>
              </CardContent>
            </Card>
          </GridItem>
        );
      })}

    </GridContainer>
  );
}
